<template>
  <div class="login-p">
    <div
      class="from-box"
      :style="{ marginTop: loginType == 3 ? '.8rem' : '1.7rem' }"
    >
      <div class="header">
        {{ ptName }}
      </div>
      <div class="form">
        <div class="h1">{{ loginType == 3 ? "修改密码" : "欢迎登录" }}</div>
        <div class="h2">
          {{ loginType == 3 ? "Change Password" : "Welcome to login" }}
          <div class="line"></div>
        </div>
        <div class="box" v-if="loginType == 1">
          <div class="inp">
            <el-input placeholder="请输入帐号" v-model="account" clearable>
            </el-input>
          </div>
          <div class="inp">
            <el-input
              placeholder="请输入登录密码"
              v-model="pwd"
              show-password
            > 
            </el-input>
          </div>
          <button class="btn" @click="accountLogin">立即登录</button>
          <p class="o-login" style="display: flex;justify-content: space-between">
            <span @click="open = true">查看详情</span>
            <span @click="changeLogin(2)">验证码登录</span>
          </p>
        </div>
        <div class="box" v-if="loginType == 2">
          <div class="inp">
            <el-input placeholder="请输入手机号" v-model="mobile" clearable>
            </el-input>
          </div>
          <div class="inp cf">
            <el-input placeholder="请输入验证码" v-model="code"> </el-input>
            <button class="get-code" @click="getCode">{{ hint }}</button>
          </div>
          <button class="btn" @click="codeLogin">立即登录</button>
<!--          <p class="o-login" @click="changeLogin(1)">密码登录</p>-->
          <p class="o-login" style="display: flex;justify-content: space-between">
            <span @click="open = true">查看详情</span>
            <span @click="changeLogin(1)">密码登录</span>
          </p>
        </div>
        <div class="box" v-if="loginType == 3">
          <div class="inp">
            <el-input placeholder="输入新密码" v-model="newPwd" show-password>
            </el-input>
          </div>
          <div class="inp">
            <el-input placeholder="确认新密码" v-model="confirm_pwd" show-password>
            </el-input>
          </div>
          <div class="inp">
            <el-input placeholder="填报人" v-model="fill_name" clearable>
            </el-input>
          </div>
          <div class="inp">
            <el-input placeholder="填报人手机号" v-model="newMobile" clearable>
            </el-input>
          </div>
          <div class="inp cf">
            <el-input placeholder="请输入验证码" v-model="newCode"> </el-input>
            <button class="get-code" @click="getCodeChange">{{hint}}</button>
          </div>
          <button class="btn" @click="editInfoByMobileCode">确定修改</button>
        </div>
      </div>
    </div>
    <div class="model" v-if="open">
      <div class="model-box">
        <div class="m-header cf">
          <p class="titles fl">平台介绍</p>
          <img
              @click="open = false"
              class="close fr"
              src="../../assets/image/tc_b_gb.png"
              alt=""
          />
        </div>
        <div>
          <div v-html="content"></div>
        </div>
      </div>
    </div>

    <div class="keepOnRecord" @click="handleKeepOnRecord" style="cursor:pointer;">备案号：鲁ICP备2022015660号-2</div>
  </div>
</template>

<script>
import mixins from "./mixins/index";
import { mapState, mapMutations } from "vuex";
export default {
  mixins,
  components: {},
  computed: {
    ...mapState(["ptName"]),
  },
  methods: {
    ...mapMutations(["setUserInfo"]),
  },
};
</script>

<style scoped>
@import "./src/style.css";

.keepOnRecord{
  text-align: center;
  width: 100%;
  position: absolute;
  bottom: 30px;
  color: #fff;
}
</style>